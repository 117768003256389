.odules__Models__components__ModelViewer__Header__Header_styles_ts-contentWrapper-c1v05wyv{margin:0 -2.5rem;padding:20px 2.5rem 0 2.5rem;}
.odules__Models__components__ModelViewer__Header__Header_styles_ts-headerWrapper-hdxmr0{background-color:white;margin:0 -2.5rem 0 -2.5rem;padding:calc(2.8rem / 2);border-bottom:rgb(228,231,236) 1px solid;top:0px;}@media (max-width:799.98px){.odules__Models__components__ModelViewer__Header__Header_styles_ts-headerWrapper-hdxmr0{padding-top:0px;}}
.odules__Models__components__ModelViewer__Header__Header_styles_ts-intersectionFillerDiv-ioyzqsv{box-sizing:border-box;margin:0 -2.5rem;display:block;}
.odules__Models__components__ModelViewer__Header__Header_styles_ts-intersectionDetecterDiv-i1cj5o13{margin:-2.5rem -2.5rem 0 -2.5rem;display:block;height:1px;}
.odules__Models__components__ModelViewer__Header__Header_styles_ts-starsContainer-s1y2q1ls{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-right:1.75rem;}
.odules__Models__components__ModelViewer__Header__Header_styles_ts-starIconWrapper-slfans9{margin-right:0 !important;}
.odules__Models__components__ModelViewer__Header__Header_styles_ts-starCounter-s10ujw0g{font-weight:500;margin-left:0.375rem;}.theme-light .odules__Models__components__ModelViewer__Header__Header_styles_ts-starCounter-s10ujw0g{color:#667085;}.theme-dark .odules__Models__components__ModelViewer__Header__Header_styles_ts-starCounter-s10ujw0g{color:#667085;}.odules__Models__components__ModelViewer__Header__Header_styles_ts-starCounter-s10ujw0g.hidden{display:none;}
.odules__Models__components__ModelViewer__Header__Header_styles_ts-actionsWrapper-arw6j2f{margin-right:0 !important;}
.odules__Models__components__ModelViewer__Header__Header_styles_ts-actionsIcon-a9qsfc2{padding-right:0;padding-left:0;}
.odules__Models__components__ModelViewer__Header__Header_styles_ts-breadCrumbs-bacqd0{font-size:14px;font-weight:500;}.odules__Models__components__ModelViewer__Header__Header_styles_ts-breadCrumbs-bacqd0 li:not(:first-child):before{background-image:url('/icons/models/breadcrumbsSeparator2.svg');}.odules__Models__components__ModelViewer__Header__Header_styles_ts-breadCrumbs-bacqd0 li:not(:last-child){color:#D0D5DD;}.odules__Models__components__ModelViewer__Header__Header_styles_ts-breadCrumbs-bacqd0 li:last-child{color:#101828;}
.odules__Models__components__ModelViewer__Header__Header_styles_ts-bottomBorderOverride-bt1y0o0{border-bottom:none !important;}
.odules__Models__components__ModelViewer__Header__Header_styles_ts-tooltipCls-tk0dw3y{border-radius:1rem;padding:0.2rem 0.5rem;margin-top:-1rem;background-color:rgba(23,31,41);}.odules__Models__components__ModelViewer__Header__Header_styles_ts-tooltipCls-tk0dw3y + .darkArrow{margin-top:-0.8px;}.odules__Models__components__ModelViewer__Header__Header_styles_ts-tooltipCls-tk0dw3y + .darkArrow svg{fill:rgba(23,31,41) !important;stroke:rgba(23,31,41);}
