.odules__Sidebar__Sidebar_styles_ts-CircularButton-csa6cro{width:1.25rem;height:1.25rem;border-radius:50%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:absolute;top:1.25rem;right:-0.625rem;z-index:999;}.theme-light .odules__Sidebar__Sidebar_styles_ts-CircularButton-csa6cro{background:#ffffff;border:1px solid #E4E7EC;color:#667085;}.theme-dark .odules__Sidebar__Sidebar_styles_ts-CircularButton-csa6cro{background:black;border:1px solid #E4E7EC;color:#667085;}.theme-light .odules__Sidebar__Sidebar_styles_ts-CircularButton-csa6cro:hover{color:#344054;}.theme-dark .odules__Sidebar__Sidebar_styles_ts-CircularButton-csa6cro:hover{color:#344054;}
.odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2{gap:0.75rem;width:100%;padding:0.625rem;background-color:transparent;border:1px solid transparent;border-radius:0.25rem;}.theme-light .odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2{color:#98A2B3;}.theme-dark .odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2{color:#98A2B3;}.odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2[data-collapsed="true"]{padding-left:0;padding-right:0;}.theme-light .odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2:hover[data-collapsed='false'],.theme-light .odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2[aria-expanded='true'][data-collapsed='false']{background-color:#ffffff;border-color:#E4E7EC;}.theme-dark .odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2:hover[data-collapsed='false'],.theme-dark .odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2[aria-expanded='true'][data-collapsed='false']{background-color:black;border-color:#E4E7EC;}.odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2 .appid,.odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2 .userid{width:-webkit-max-content;width:-moz-max-content;width:max-content;font-size:0.875rem;}.odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2 .appid{font-weight:500;}.theme-light .odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2 .appid{color:#344054;}.theme-dark .odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2 .appid{color:#344054;}.odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2 .userid{font-weight:400;}.theme-light .odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2 .userid{color:#195AFF;}.theme-dark .odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2 .userid{color:#195AFF;}.odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2 .userid:hover{-webkit-text-decoration:underline;text-decoration:underline;}.odules__Sidebar__Sidebar_styles_ts-appInfoArea-a27n4g2 .app-thumbnail{z-index:99;}
.odules__Sidebar__Sidebar_styles_ts-listWithGap-lmaz8vj{gap:1.5rem;}
.odules__Sidebar__Sidebar_styles_ts-sidebarWrapper-sjeirct{min-width:16rem;-webkit-transition:width 0.2s ease-in-out;transition:width 0.2s ease-in-out;position:relative;}.theme-light .odules__Sidebar__Sidebar_styles_ts-sidebarWrapper-sjeirct{background-color:#FCFCFD;color:#282636;border-right:1px solid #E4E7EC;}.theme-dark .odules__Sidebar__Sidebar_styles_ts-sidebarWrapper-sjeirct{background-color:#FCFCFD;color:white;border-right:1px solid #E4E7EC;}
.odules__Sidebar__Sidebar_styles_ts-mobileSidebar-mo9vy5c{gap:0.875rem;}.theme-light .odules__Sidebar__Sidebar_styles_ts-mobileSidebar-mo9vy5c{background-color:#FCFCFD;border-bottom:1px solid #E4E7EC;}.theme-dark .odules__Sidebar__Sidebar_styles_ts-mobileSidebar-mo9vy5c{background-color:#FCFCFD;border-bottom:1px solid #E4E7EC;}
.odules__Sidebar__Sidebar_styles_ts-mobilePopoverStyles-mogebx2{max-width:100% !important;border-radius:8px;position:fixed !important;width:100% !important;bottom:0 !important;top:auto !important;overflow:hidden;-webkit-transform:translate3d(0px,0px,0px) !important;-ms-transform:translate3d(0px,0px,0px) !important;transform:translate3d(0px,0px,0px) !important;max-height:70vh;min-height:22vh;}
.odules__Sidebar__Sidebar_styles_ts-sidebarScrollableContainer-s2pql3t{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;overflow:visible;position:relative;width:100%;height:100%;padding:0.875rem 1rem;}@media (min-width:800px){.odules__Sidebar__Sidebar_styles_ts-sidebarScrollableContainer-s2pql3t{padding:1.25rem 1rem;}}
.odules__Sidebar__Sidebar_styles_ts-motionMenuIcon-mjvjtj8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}

.odules__Sidebar__Sidebar_styles_ts-mobileMenu-mi6sxnu{padding:0;}
.odules__Sidebar__Sidebar_styles_ts-submenuIcon-s12jwtr2{-webkit-transform:rotate(90deg);-ms-transform:rotate(90deg);transform:rotate(90deg);}
.odules__Sidebar__Sidebar_styles_ts-shrinkText-s1a8xmdc{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:100%;min-width:0;}
.odules__Sidebar__Sidebar_styles_ts-appNameArea-aexlod4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;min-width:0;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;}
.odules__Sidebar__Sidebar_styles_ts-userArea-u18l8aq3{position:relative;z-index:1031;padding:calc( 2.8rem / 2 );padding-left:0;}.theme-light .odules__Sidebar__Sidebar_styles_ts-userArea-u18l8aq3{background-color:#F3F3F8;border-bottom:1px solid rgb(0 0 0 / 7%);}.theme-dark .odules__Sidebar__Sidebar_styles_ts-userArea-u18l8aq3{background-color:#2c2c2c;border-bottom:1px solid rgb(58 58 59);}.odules__Sidebar__Sidebar_styles_ts-userArea-u18l8aq3 > div{height:2.5rem;font-weight:500;overflow:visible;display:grid;grid-template-columns:2.5rem 1fr;}@media (max-width:799.98px){.odules__Sidebar__Sidebar_styles_ts-userArea-u18l8aq3 > div{grid-template-columns:2.5rem 2.5rem 1fr;}}.odules__Sidebar__Sidebar_styles_ts-userArea-u18l8aq3 > div .app-title{padding-left:1rem;line-height:2.8rem;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:grid;grid-template-columns:1fr;}.theme-light .odules__Sidebar__Sidebar_styles_ts-userArea-u18l8aq3 > div .app-title .appid{color:#195AFF;}.theme-dark .odules__Sidebar__Sidebar_styles_ts-userArea-u18l8aq3 > div .app-title .appid{color:#4c7fff;}.odules__Sidebar__Sidebar_styles_ts-userArea-u18l8aq3 > div .app-title .appid em{padding-left:0.2rem;}.theme-light .odules__Sidebar__Sidebar_styles_ts-userArea-u18l8aq3 > div .app-title .appid em{color:#808080;}.theme-dark .odules__Sidebar__Sidebar_styles_ts-userArea-u18l8aq3 > div .app-title .appid em{color:#808080;}.odules__Sidebar__Sidebar_styles_ts-userArea-u18l8aq3 > div .app-title .appid:hover{-webkit-text-decoration:underline;text-decoration:underline;}
.odules__Sidebar__Sidebar_styles_ts-intersectionDetecterDiv-i1jnsjwf{top:0rem;position:absolute;display:block;height:1px;width:100%;}
.odules__Sidebar__Sidebar_styles_ts-isNotCollapsedSticky-i1l3vyqx{position:fixed;z-index:99999;left:17.4rem;top:0rem;}
.odules__Sidebar__Sidebar_styles_ts-isCollapsedSticky-icrczd6{position:fixed;z-index:99999;left:4.4rem;top:0rem;}
.odules__Sidebar__Sidebar_styles_ts-isNotSticky-ir0024v{position:static;z-index:auto;}
