.odules__Organizations__components__CreateOrganization__styles_ts-createOrganizationWrapper-c13gulul #info-alert{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;margin-top:1rem;padding:0.5rem 1rem;}.odules__Organizations__components__CreateOrganization__styles_ts-createOrganizationWrapper-c13gulul .mobile-close-btn{margin-left:auto;height:auto !important;}.theme-light .odules__Organizations__components__CreateOrganization__styles_ts-createOrganizationWrapper-c13gulul .mobile-close-btn-wrapper{border-bottom:1px solid #E4E7EC;}.theme-dark .odules__Organizations__components__CreateOrganization__styles_ts-createOrganizationWrapper-c13gulul .mobile-close-btn-wrapper{border-bottom:1px solid #E4E7EC;}
.odules__Organizations__components__CreateOrganization__styles_ts-orgInfoFormStyles-o108p78k{width:32.5rem !important;}
.odules__Organizations__components__CreateOrganization__styles_ts-formStyles-f1ssltvh{gap:1.25rem;height:100%;}
.odules__Organizations__components__CreateOrganization__styles_ts-buttonWrapper-bngcuw4{width:100%;margin-top:auto;gap:1rem;}@media (max-width:799.98px){.odules__Organizations__components__CreateOrganization__styles_ts-buttonWrapper-bngcuw4{-webkit-flex-direction:column-reverse !important;-ms-flex-direction:column-reverse !important;flex-direction:column-reverse !important;}.odules__Organizations__components__CreateOrganization__styles_ts-buttonWrapper-bngcuw4 > button{width:100%;}}
.odules__Organizations__components__CreateOrganization__styles_ts-addEmailBtn-a47ohr0{width:-webkit-max-content;width:-moz-max-content;width:max-content;margin-bottom:1rem;}
.odules__Organizations__components__CreateOrganization__styles_ts-dropdownOptionWrapper-dnqbur8{min-width:auto !important;}
.odules__Organizations__components__CreateOrganization__styles_ts-dropdownWrapper-d1q7e99v button[role='menuitem']{max-width:100%;}
.odules__Organizations__components__CreateOrganization__styles_ts-heading-h2wp4sc{font-size:1.5rem;font-weight:600;margin-bottom:1.5rem;}.theme-light .odules__Organizations__components__CreateOrganization__styles_ts-heading-h2wp4sc{color:#282636;}.theme-dark .odules__Organizations__components__CreateOrganization__styles_ts-heading-h2wp4sc{color:white;}
.odules__Organizations__components__CreateOrganization__styles_ts-subheading-s16h2tft{margin-bottom:1rem;}.theme-light .odules__Organizations__components__CreateOrganization__styles_ts-subheading-s16h2tft{color:#282636;}.theme-dark .odules__Organizations__components__CreateOrganization__styles_ts-subheading-s16h2tft{color:white;}
.odules__Organizations__components__CreateOrganization__styles_ts-closeIcon-clmzcgz{cursor:pointer;position:absolute;top:calc( 2.8rem / 2 );right:calc( 2.8rem / 2 );-webkit-transition:all 0.2s ease;transition:all 0.2s ease;}.odules__Organizations__components__CreateOrganization__styles_ts-closeIcon-clmzcgz:hover{opacity:0.5;}
