.odules__Auth__components__styles_ts-annotation-aa3ang5{color:rgba(0,0,0,0.5);}
.odules__Auth__components__styles_ts-authWrapper-ai7dznc{background-color:white;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;position:absolute;top:0;min-height:100%;width:100%;}@media (min-width:800px){.odules__Auth__components__styles_ts-authWrapper-ai7dznc{-webkit-flex-direction:row-reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;}}@media (max-width:799.98px){.odules__Auth__components__styles_ts-authWrapper-ai7dznc .banner{display:none;}}
.odules__Auth__components__styles_ts-authBannerBackground-a1o4jzp9{position:relative;height:100%;}@media (min-width:800px){.odules__Auth__components__styles_ts-authBannerBackground-a1o4jzp9{display:block;}}
@media (max-width:799.98px){.odules__Auth__components__styles_ts-authBannerLogin-a1ccz62s{display:none;}}
.odules__Auth__components__styles_ts-authBannerWrapper-aj8i4qp{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;height:100%;padding:calc( 2.8rem * 2 );overflow:hidden;max-width:100rem;margin:0 auto;}
.odules__Auth__components__styles_ts-BannerWrapper-buqbpk2{background-color:#0a0d15;background-image:url(/clarifai-logo-banner-background.svg);background-size:cover;width:100%;position:relative;}@media (min-width:800px){.odules__Auth__components__styles_ts-BannerWrapper-buqbpk2 .bannerFooter{display:none;}}@media (max-width:799.98px){.odules__Auth__components__styles_ts-BannerWrapper-buqbpk2{display:none;}}
.odules__Auth__components__styles_ts-Content-c1177jxa{position:relative;z-index:1;}
.odules__Auth__components__styles_ts-loginBannerImage-l1cg10qp{display:block;margin:0 auto;max-width:100%;}
.odules__Auth__components__styles_ts-logoLink-lbwtdec{outline:none;}
.odules__Auth__components__styles_ts-LogoImage-llew7tn{height:2.8rem;width:calc(2.8rem * 4.5);margin-top:2.8rem;background:url(/clarifai-logo-with-name.svg) no-repeat;margin-bottom:2.2rem;}
.odules__Auth__components__styles_ts-formWrapper-ftpz8u{width:100%;padding:2.8rem 0;}@media (min-width:800px){.odules__Auth__components__styles_ts-formWrapper-ftpz8u{margin:0 auto;}}
.odules__Auth__components__styles_ts-authContainer-ahfbni1{background:white;width:100%;position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.odules__Auth__components__styles_ts-authContainer-ahfbni1 .footer-auth-area{position:absolute;bottom:0;width:100%;padding-bottom:calc( 2.8rem / 2 );}@media (max-width:799.98px){.odules__Auth__components__styles_ts-authContainer-ahfbni1{padding-bottom:1.25rem;}.odules__Auth__components__styles_ts-authContainer-ahfbni1 .footer-auth-area{display:none;}}
.odules__Auth__components__styles_ts-authContent-a49mtv6{position:relative;height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:100%;max-width:calc(120rem / 2);tyles__utils__core__cssHelpers_ts-init-iida6s1:tyles__utils__core__cssHelpers_ts-directionColumn-d1q5ldab;}
.odules__Auth__components__styles_ts-footerContainer-f1buqzl{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}@media (max-width:319.98px){.odules__Auth__components__styles_ts-footerContainer-f1buqzl{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}}@media (max-width:799.98px){.odules__Auth__components__styles_ts-footerContainer-f1buqzl{padding-bottom:1.25rem;}}
.odules__Auth__components__styles_ts-footerWrapper-f16jk7a4{padding:0 calc(2.8rem / 2.5) 0;}
.odules__Auth__components__styles_ts-footerLinkWrapper-f17t465z{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;}
.odules__Auth__components__styles_ts-rowInputs-r146z0jx{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}@media (max-width:319.98px){.odules__Auth__components__styles_ts-rowInputs-r146z0jx{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}}
.odules__Auth__components__styles_ts-clientLogo-ce9vjjs{height:35px;object-fit:cover;margin-bottom:10px;}
.odules__Auth__components__styles_ts-heading-h1da17jc{color:#001236;font-size:calc(2.8rem / 1.4);font-weight:600;line-height:32px;}
.odules__Auth__components__styles_ts-textDefault-t50gnrx{color:rgba(0,0,0,0.5);font-size:calc(2.8rem / 3);font-weight:normal;}
.odules__Auth__components__styles_ts-textBold-t1280hk5{font-weight:bold;}
.odules__Auth__components__styles_ts-textBlack-t7y4q21{color:rgba(0,0,0,1);}
.odules__Auth__components__styles_ts-textGrey-txlh48f{color:rgba(0,0,0,0.5);}
.odules__Auth__components__styles_ts-textLink-t1vn4rur{display:inline-block;font-size:calc(2.8rem / 3);margin:0.25rem 0.2rem 0;}.theme-light .odules__Auth__components__styles_ts-textLink-t1vn4rur{border-bottom:0.1rem solid #195AFF;color:#195AFF;}.theme-dark .odules__Auth__components__styles_ts-textLink-t1vn4rur{border-bottom:0.1rem solid #4c7fff;color:#4c7fff;}.theme-light .odules__Auth__components__styles_ts-textLink-t1vn4rur:hover{border-bottom:0.1rem solid #195AFF;}.theme-dark .odules__Auth__components__styles_ts-textLink-t1vn4rur:hover{border-bottom:0.1rem solid #4c7fff;}
.odules__Auth__components__styles_ts-textLinkExternal-t1uiy6ai{display:inline-block;font-size:inherit;}.theme-light .odules__Auth__components__styles_ts-textLinkExternal-t1uiy6ai{border-bottom:1px solid transparent;color:#195AFF;}.theme-dark .odules__Auth__components__styles_ts-textLinkExternal-t1uiy6ai{border-bottom:1px solid transparent;color:#195AFF;}.theme-light .odules__Auth__components__styles_ts-textLinkExternal-t1uiy6ai:hover{border-bottom:1px solid #195AFF;}.theme-dark .odules__Auth__components__styles_ts-textLinkExternal-t1uiy6ai:hover{border-bottom:1px solid #195AFF;}
.odules__Auth__components__styles_ts-textLinkGreyExternal-t1gu4o9w{border-bottom:1px solid transparent;color:rgba(0,0,0,0.5);display:inline-block;font-size:calc(2.8rem / 3);margin:0 6px;-webkit-transition:all 0.25s ease;transition:all 0.25s ease;}.odules__Auth__components__styles_ts-textLinkGreyExternal-t1gu4o9w:hover{color:rgba(0,0,0,0.75);border-bottom:1px solid rgba(0,0,0,0.5);}
.odules__Auth__components__styles_ts-textLegal-t1yc5ji1{color:rgba(0,0,0,0.5);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.odules__Auth__components__styles_ts-checkboxLabel-c1u83gqo label{margin:0.5rem 0;font-size:1rem !important;font-weight:400 !important;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.75rem;}.theme-light .odules__Auth__components__styles_ts-checkboxLabel-c1u83gqo label{color:#344054;}.theme-dark .odules__Auth__components__styles_ts-checkboxLabel-c1u83gqo label{color:#344054;}.odules__Auth__components__styles_ts-checkboxLabel-c1u83gqo label span{white-space:wrap;}.odules__Auth__components__styles_ts-checkboxLabel-c1u83gqo label input{height:auto !important;}
.odules__Auth__components__styles_ts-buttonAuth-bezl1y5{border-radius:calc(2.8rem / 5);background:rgba(249,251,255,1);color:rgba(10,13,21,1);display:block;font-weight:600;-webkit-letter-spacing:0.05rem;-moz-letter-spacing:0.05rem;-ms-letter-spacing:0.05rem;letter-spacing:0.05rem;text-align:center;padding:calc(2.8rem / 3) calc( 2.8rem / 2 );width:100%;}.odules__Auth__components__styles_ts-buttonAuth-bezl1y5:hover{background:rgb(229,238,255);boxshadow:0 0 0 1px rgb(199,217,255) inset;}
.odules__Auth__components__styles_ts-buttonDisabled-bhmqfwq{opacity:0.65;pointer-events:none;}
.odules__Auth__components__styles_ts-authCheckboxImage-ad4cj7a{background:url(/auth_icons/checkbox.svg) no-repeat;background-size:contain;margin-top:2.5px;margin-right:10px;height:24px !important;min-width:24px !important;}
.odules__Auth__components__styles_ts-authHeading-a152jw2j{color:white;font-weight:bold;font-size:2.5rem;margin-top:1.25rem;}@media (max-width:799.98px){.odules__Auth__components__styles_ts-authHeading-a152jw2j{font-size:1.625rem;padding-right:1.25rem;}}
.odules__Auth__components__styles_ts-authSubHeading-a1y3psvn{color:white;font-weight:bold;font-size:16px;margin-top:21px;margin-bottom:15px;text-transform:uppercase;-webkit-letter-spacing:0.05rem;-moz-letter-spacing:0.05rem;-ms-letter-spacing:0.05rem;letter-spacing:0.05rem;}@media (max-width:799.98px){.odules__Auth__components__styles_ts-authSubHeading-a1y3psvn{font-size:0.75rem;}}
.odules__Auth__components__styles_ts-_description-_15qptpo{font-size:1.1rem;font-weight:light;margin-bottom:10px;line-height:1.5rem;}
.odules__Auth__components__styles_ts-authDescription-a13zi4am{color:rgba(255,255,255,0.7);margin-bottom:calc( 2.8rem / 2 );}
.odules__Auth__components__styles_ts-loginBannerDescription-l7tlc59{color:#7c86a1;margin:calc( 2.8rem / 2 ) 0;}
.odules__Auth__components__styles_ts-SignupContentWrapper-s16ffjrt{position:relative;tyles__utils__core__cssHelpers_ts-init-iida6s1:tyles__utils__core__cssHelpers_ts-directionColumn-d1q5ldab;}
.odules__Auth__components__styles_ts-signupForm-s1o7knwr{max-width:calc(120rem / 2);position:relative;height:100%;display:grid;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;tyles__utils__core__cssHelpers_ts-init-iida6s1:tyles__utils__core__cssHelpers_ts-directionColumn-d1q5ldab;}
.odules__Auth__components__styles_ts-signupFormNameGroup-s10rx8z0{width:100%;}.odules__Auth__components__styles_ts-signupFormNameGroup-s10rx8z0 div{width:48%;}
@media (max-width:799.98px){.odules__Auth__components__styles_ts-formGridWrapper-f9oiu8f{padding:calc( 2.8rem / 2 );}}
.odules__Auth__components__styles_ts-footerMobile-f1xrnjdb{display:none;}@media (max-width:799.98px){.odules__Auth__components__styles_ts-footerMobile-f1xrnjdb{display:block;}.theme-light .odules__Auth__components__styles_ts-footerMobile-f1xrnjdb{background-color:#ffffff;}.theme-dark .odules__Auth__components__styles_ts-footerMobile-f1xrnjdb{background-color:black;}}
.odules__Auth__components__styles_ts-rememberMeGroup-rc4bc07{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:1.5rem;}
.theme-light .odules__Auth__components__styles_ts-links-l1092hbp{color:#195AFF;}.theme-dark .odules__Auth__components__styles_ts-links-l1092hbp{color:#4c7fff;}
.odules__Auth__components__styles_ts-ssoProviderWrapper-sm0ctkd{margin-bottom:1rem;width:100%;}.odules__Auth__components__styles_ts-ssoProviderWrapper-sm0ctkd hr{line-height:1em;position:relative;outline:0;border:0;color:#818078;text-align:center;opacity:0.5;margin:0.9rem 0;}.odules__Auth__components__styles_ts-ssoProviderWrapper-sm0ctkd hr:before{content:'';background:#818078;position:absolute;left:0;top:50%;width:100%;height:1px;}.odules__Auth__components__styles_ts-ssoProviderWrapper-sm0ctkd hr:after{content:attr(data-content);position:relative;display:inline-block;padding:0 0.5em;line-height:1.5em;background-color:#fcfcfa;}.theme-light .odules__Auth__components__styles_ts-ssoProviderWrapper-sm0ctkd hr:after{color:#282636;}.theme-dark .odules__Auth__components__styles_ts-ssoProviderWrapper-sm0ctkd hr:after{color:white;}
.odules__Auth__components__styles_ts-ssoButton-s7nqb3h{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#666;cursor:pointer;height:2.5rem;position:relative;margin-bottom:0.75rem;box-shadow:0 1px 2px rgb(16 24 40 / 5%);border-radius:0.5rem;}.theme-light .odules__Auth__components__styles_ts-ssoButton-s7nqb3h{border:1px solid #D0D5DD;}.theme-dark .odules__Auth__components__styles_ts-ssoButton-s7nqb3h{border:1px solid #D0D5DD;}.odules__Auth__components__styles_ts-ssoButton-s7nqb3h .icon{position:absolute;left:32%;height:20px;width:20px;margin-right:1.25rem;}.odules__Auth__components__styles_ts-ssoButton-s7nqb3h .text-wrapper{box-sizing:border-box;cursor:pointer;font-weight:500;outline:#ffffff none 0;-webkit-transition:all 0.3s ease;transition:all 0.3s ease;padding-left:10px;position:absolute;left:37%;text-align:center;font-size:0.875rem;line-height:1.25rem;}.odules__Auth__components__styles_ts-ssoButton-s7nqb3h span.sso-provider{text-transform:capitalize;}@media (max-width:319.98px){.odules__Auth__components__styles_ts-ssoButton-s7nqb3h .icon{left:23%;}.odules__Auth__components__styles_ts-ssoButton-s7nqb3h .text-wrapper{left:30%;}}
.odules__Auth__components__styles_ts-ssoProviderContainer-s13fyuvm{position:relative;}
.odules__Auth__components__styles_ts-ssoProviderSpinner-srskmce{position:absolute;top:0;bottom:0;left:0;right:0;z-index:1;}
